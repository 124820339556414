<template>
  <div id="warehouse">
    <div class="searchBox">
      <div class="myInput">
        <i class="el-icon-search"></i>
        <input type="text" v-model="form.searchText" @keydown.enter="getData()" :placeholder="$fanyi('请输入关键词搜索')" />
      </div>
      <button class="searchBtn" @click="getData()">
        {{ $fanyi('搜索') }}
      </button>
    </div>
    <!-- 表格 -->
    <div class="dataBody">
      <div class="myTable">
        <div class="tableHead">
          <div class="Num detailtd" title="NO">No</div>
          <div class="picture detailtd" :title="$fanyi('照片')">
            {{ $fanyi('照片') }}
          </div>
          <div class="detail detailtd" :title="$fanyi('属性')">
            {{ $fanyi('属性') }}
          </div>
          <div class="Quantity detailtd" :title="$fanyi('数量')">
            {{ $fanyi('数量') }}
          </div>
          <div class="price detailtd" :title="$fanyi('单价')">
            {{ $fanyi('单价') }}(RMB)
          </div>
          <div class="total detailtd" :title="$fanyi('产品费用')">
            {{ $fanyi('产品费用') }}
          </div>
          <div class="freight detailtd" :title="$fanyi('国内运费')">
            {{ $fanyi('国内运费') }}
          </div>
          <div class="options detailtd" :title="$fanyi('附加服务费用')">
            {{ $fanyi('附加服务费用') }}
          </div>
          <div class="buyNum detailtd" :title="$fanyi('可提货数量')" v-if="form.status == 'status_submit'">
            {{ $fanyi('可提货数量') }}
          </div>
          <div class="deliverableQuantity detailtd" :title="$fanyi('已提货数量')" v-else>
            {{ $fanyi('已提货数量') }}
          </div>
          <div class="remark detailtd" :title="$fanyi('备注栏')">
            {{ $fanyi('备注栏') }}
          </div>
        </div>
        <div class="tableBody" v-loading="loading">
          <div class="tableBodyOpt" v-for="(tableDataItem, tableIndex) in tableData" :key="tableIndex">
            <!-- 订单数据行 -->
            <div class="optTitle">
              <div class="serialNumber">
                <img @click="
                  tableDataItem.showChildData = !tableDataItem.showChildData
                $forceUpdate()
                  " v-bind:class="{
                    openIcon: tableDataItem.showChildData == true,
                  }" :src="tableDataItem.showChildData == true
                    ? require('../../../../assets/warehouse_1.svg')
                    : require('../../../../assets/warehouse_0.svg')
                    " alt="" />
                <span>{{ $fanyi('订单号') }} :</span>
                <button @click="
                  $fun.toPage(
                    '/orderDetails?type=purchase&order_sn=' +
                    tableDataItem.order_sn
                  )
                  ">
                  {{ tableDataItem.order_sn }}
                </button>
              </div>
            </div>
            <!-- 订单详情数据行 -->
            <div class="optDetailGroup" v-bind:class="{ off: tableDataItem.showChildData == false }">
              <div class="optDetail" v-for="(goodsItem, goodsIndex) in tableDataItem.order_detail" :key="goodsIndex">

                <div class="Num detailtd">{{ goodsIndex + 1 }}</div>
                <div class="picture detailtd" style="cursor: pointer" @click="
                  $fun.toCommodityDetails({
                    goods_id: goodsItem.goods_id,
                    shop_type: goodsItem.shop_type,
                  })
                  ">
                  <el-popover placement="right" trigger="hover">
                    <img :src="goodsItem.pic" alt="" style="width: 300px; height: 300px" />

                    <el-image :src="goodsItem.pic" @click="
                      $fun.toCommodityDetails({
                        goods_id: goodsItem.goods_id,
                        shop_type: goodsItem.shop_type,
                      })
                      " style="width: 60px; height: 60px" slot="reference">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </el-popover>
                </div>
                <div class="detail detailtd">
                  <el-popover placement="bottom" trigger="hover">
                    <div class="goodsDetailAll">
                      <div class="goodsDetailAllOneBox" v-for="(detailItem, detailIndex) in goodsItem.detail"
                        :key="detailIndex" :title="detailItem.key + ':' + detailItem.value">
                        <h1 class="detailTitle">{{ detailItem.key }}</h1>
                        <p>
                          <span class="detailBody">{{
                            detailItem.value
                          }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="detailBox" slot="reference" style="cursor: pointer" @click="
                      $fun.toCommodityDetails({
                        goods_id: goodsItem.goods_id,
                        shop_type: goodsItem.shop_type,
                      })
                      ">
                      <p v-for="(item, index) in goodsItem.detail" :key="index" :title="item.key + ':' + item.value">
                        <span class="detailKey">{{ item.key }}</span>
                        ：
                        <span class="detailValue">{{ item.value }}</span>
                      </p>
                    </div>
                  </el-popover>
                </div>
                <div class="Quantity detailtd">{{ goodsItem.confirm_num }}</div>

                <div class="price detailtd">
                  {{ $fun.RMBNumSegmentation(goodsItem.confirm_price) }}
                </div>
                <div class="total detailtd">
                  {{ $fun.RMBNumSegmentation(goodsItem.totalPrice) }}
                </div>
                <div class="freight detailtd">
                  {{ $fun.RMBNumSegmentation(goodsItem.confirm_freight) }}
                </div>
                <div class="options detailtd">
                  <el-popover placement="bottom" width="630" trigger="click">
                    <el-table :data="goodsItem.option" :header-cell-style="{
                      'text-align': 'center',
                    }" :cell-style="{ 'text-align': 'center' }" size="mini">
                      <el-table-column property="tag" label=""></el-table-column>
                      <el-table-column property="name" :label="$fanyi('名称')">
                        <template slot-scope="scope">
                          <div style="
                                                                              overflow: hidden;
                                                                              text-overflow: ellipsis;
                                                                              white-space: nowrap;
                                                                            " :title="scope.row.name_translate">
                            {{ scope.row.name_translate }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column property="num" :label="$fanyi('数量')"></el-table-column>
                      <el-table-column property="price" :label="$fanyi('单价')" width="130px">
                        <template slot-scope="scope">
                          <div>
                            ${{
                              $fun.EURNumSegmentation(
                                $fun.ceil(scope.row.price * $store.state.exchangeRate)
                              )
                            }}

                            ({{
                              $fun.EURNumSegmentation(
                                $fun.ceil(scope.row.price)
                              )
                            }}￥)
                          </div>
                        </template></el-table-column>
                      <el-table-column property="price" width="150px" :label="$fanyi('总价')">
                        <template slot-scope="scope">
                          <div>
                            ${{
                              $fun.EURNumSegmentation(
                                $fun.ceil(
                                  scope.row.price *
                                  scope.row.num *
                                  $store.state.exchangeRate
                                )
                              )
                            }}

                            ({{
                              $fun.EURNumSegmentation(
                                $fun.ceil(scope.row.price * scope.row.num)
                              )
                            }}￥)
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- 附加服务 -->
                    <div class="showOption" slot="reference">
                      <div class="showOptionCon">
                        <p v-show="optionItem.num > 0" v-for="(optionItem, opionIndex) in goodsItem.option"
                          :key="opionIndex">
                          <span class="tagBox">{{ optionItem.tag }} </span>
                          {{ optionItem.name_translate }} *
                          {{ optionItem.num }}
                        </p>
                      </div>
                    </div>
                  </el-popover>
                </div>
                <!-- 可提货数量 -->
                <div class="buyNum detailtd" v-if="form.status == 'status_submit'">
                  {{ goodsItem.totalWaitSubmitNum }}
                </div>
                <!-- 已提货数量 -->
                <div class="deliverableQuantity detailtd" v-else>
                  {{ goodsItem.totalWaitFreightNum }}
                </div>
                <div class="remark detailtd">
                  <div class="client_remark_Box">
                    {{ goodsItem.client_remark }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="tableData.length == 0" class="nodata">No Data</div>
        </div>


      </div>
    </div>
    <div class="allpag">
      <span></span>
      <el-pagination @current-change="handleCurrentChange" :current-page="form.currentPage" :page-size="10"
        :page-sizes="[10, 20, 30, 50]" layout=" prev, pager, next" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        status: 'status_submit',
        searchText: '',
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      loading: false,
      total: 0,
      actIds: [],
      page: 1,
      pageSize: 10,
      checked: false, //是否全选

    }
  },
  components: {},
  computed: {},
  created() {

    this.getData()
  },
  methods: {
    // 获取数据
    getData() {
      this.loading = true
      let datas = {
        status: this.form.status,
        keywords: this.form.searchText,
        page: this.form.page,
        pageSize: this.form.pageSize,
      }
      this.$api.storageList(datas).then((res) => {
        this.loading = false
        if (res.code != 0) return false
        if (res.data.length == 0) return false

        this.total = res.data.total
        this.form.currentPage = res.data.currentPage
        this.tableData = res.data.data

        this.dataProcessing(this.tableData)
      })
    },
    // 数据处理
    dataProcessing(data) {
      data.forEach((dataItem) => {
        // 添加是否显示子数据的参数
        dataItem.showChildData = true
        dataItem.order_detail.forEach((goodsItem) => {
          // 添加多选框参数
          goodsItem.checked = false
          // 商品小计计算
          goodsItem.totalPrice = this.$fun.roundNumber(
            goodsItem.confirm_price * goodsItem.confirm_num,
            2
          )
        })
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getData()
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
#warehouse {
  @import '../../../../css/mixin';
  width: 1180px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #E2E2E2;
  overflow: hidden;
  padding: 30px;
  padding-bottom: 20px;


  .searchBox {
    display: flex;
    margin-bottom: 20px;

    .myInput {
      width: 400px;
      height: 40px;
      border-radius: 4px;

      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-right: 20px;

      >i {
        width: 20px;
        height: 24px;
        margin: 9px 11px 9px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #CDCDCD;
        position: absolute;
      }

      >input {
        border: none;
        border-radius: 4px;
        border: 1px solid #E2E2E2;
        width: 500px;
        height: 100%;
        font-size: 14px;
        padding-left: 40px;
        padding-right: 10px;
        transition: 0.3s;

        &:focus {
          border-color: #1e2997;
        }
      }
    }

    .searchBtn {
      width: 80px;
      height: 40px;
      background: $homePageSubjectColor;
      border-radius: 4px;

      color: #ffffff;
      font-size: 16px;
    }

    .shippingOrder {
      height: 40px;
      padding: 0 10px;
      background: #ffa724;
      border-radius: 4px;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
    }
  }

  // 分页
  .dataBody {
    margin-bottom: 20px;

    .myTable {
      // 设置表格所有样式
      font-size: 12px;
      color: #000000;
      line-height: 19px;

      * {
        transition: 0.3s;
      }

      // 设置表头样式
      .tableHead {
        width: 100%;
        padding: 0px 20px;
        height: 60px;
        background: #F6F6F6;
        border: 1px solid #E2E2E2;
        border-bottom: none;

        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        cursor: default;

        div {
          line-height: 14px;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
        }
      }

      // 设置表格内容样式
      .tableBody {
        display: block;
        width: 100%;
        border: 1px solid #E2E2E2;
        border-top: none;
        border-bottom: none;

        .tableBodyOpt {
          width: 100%;

          // 设置表格内容里所有的单元格样式
          >div {
            display: flex;
            align-items: center;

            >div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .optTitle {
            height: 60px;
            border-bottom: 1px solid #E2E2E2;

            &:last-child {
              border: none;
            }

            .serialNumber {
              font-size: 14px;
              width: 360px;
              justify-content: flex-start;
              padding-left: 30px;

              span {
                margin-left: 8px;
              }

              button {
                width: 171px;
                height: 20px;
                margin-right: 10px;
                background-color: transparent;
                font-weight: 400;
                color: #ff730b;

                &:hover {
                  text-decoration: underline;
                  color: #ff730b;
                }
              }

              img {
                width: 16px;
                height: 16px;
                cursor: pointer;

                // transform: rotate(90deg);
                &.openIcon {
                  transform: rotate(360deg);
                }
              }
            }
          }

          .optDetailGroup {
            transition: 0.6s !important;
            display: block;


            >div {
              text-align: center;
            }

            &.off {
              height: 0px;
              overflow: hidden;
            }

            .optDetail {
              height: 120px;
              width: 100%;
              padding: 0px 20px;
              font-weight: 500;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 14px;
              background: #fafafa;
            }
          }
        }
      }

      // 没有数据
      .nodata {
        width: 100%;
        display: flex;
        height: 60px;
        font-size: 14px;
        align-items: center;
        color: #909399;
        justify-content: center;
      }

      // 单个单元格样式
      .detailtd {
        flex: 1;
        font-size: 14px;
      }

      // 设置每个盒子的大小间距
      // NO
      .Num {
        flex: 0 0 40px;
      }

      // 照片
      .picture {
        flex: 0 0 60px;
      }

      // 属性
      .detail {
        flex: 0 0 120px !important;
        overflow-y: auto;
        padding: 10px 0;
        max-height: 120px;

        .detailBox {
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;

          >p {
            text-align: left;
          }
        }
      }

      .Quantity {
        flex: 0 0 80px;
      }

      .price {
        flex: 0 0 80px;
      }

      .total {
        flex: 0 0 80px;
      }

      .freight {
        flex: 0 0 120px;
      }

      .options,
      .showOption {
        flex: 0 0 160px;
        display: flex;
        justify-content: center;
        align-items: center;

        .showOption {
          width: 160px;
          height: 100px;
          background: #ffffff;
          border-radius: 2px;

          border: 1px solid #ededed;

          cursor: pointer;
          -webkit-box-orient: vertical;

          .showOptionCon {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            height: 90px;
            padding: 10px;

            p {
              text-align: left;
              line-height: 20px;

              .tagBox {
                position: relative;
                top: -1px;
              }
            }
          }
        }
      }

      .buyNum {
        flex: 0 0 100px;
      }

      .remark {
        flex: 0 0 160px;
        display: flex;
        justify-content: center;
        align-items: center;

        .client_remark_Box {
          width: 160px;
          height: 100px;
          text-align: left;
          padding: 10px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid #ededed;
        }
      }
    }
  }

  .allpag {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;

    .el-pagination {
      display: flex;
      align-items: center;
    }

    >a {
      display: inline-block;
      border: 1px solid #dcdfe6;
      height: 28px;
      // background-color: white;
      text-align: center;
      padding: 0 10px;
      font-size: 13px;
      line-height: 28px;
      border-radius: 0;
    }

    /deep/.btn-prev {
      width: 40px;
      height: 40px;
      background: #f5f5f5;
      border-radius: 20px;
      padding: 0;
      margin-right: 30px;

      .el-icon {
        font-size: 15px;
      }
    }

    /deep/.btn-next {
      width: 40px;
      height: 40px;
      background: #f5f5f5;
      border-radius: 20px;
      padding: 0;
      margin-left: 30px;

      .el-icon {
        font-size: 15px;
      }
    }

    /* 分页被选中的页码样式 */
    /deep/ .el-pager li {
      margin: 0 10px;
      // border-radius: 0;
      min-width: 20px !important;
      font-size: 14px;
      height: 30px;
      color: #999999;
      // box-shadow: 0 0 6px 0 rgba(61, 61, 61, 0.1);

      &:hover {
        color: #ff730b;
      }

      &.active {
        color: #ff730b;
        border-bottom: 2px solid #ff730b;
        width: 20px !important;
      }
    }
  }
}

// 弹窗样式
.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}
</style>
